<template>
  <b-modal
    id="edit-invoice-modal"
    centered
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <template #modal-title>
      <h2 class="m-0 text-colorBlack">Edit Invoice</h2>
    </template>
    <b-form>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack"
            >Organization Name
          </span>
          <b-form-group class="mb-0">
            <b-form-input
              id="organizationName"
              placeholder="Organization Name"
              v-bind="organizationName"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack"
            >Organization Number
          </span>
          <b-form-group class="mb-0">
            <b-form-input
              id="organizationNumber"
              placeholder="Organization Number"
              v-bind="organizationNumber"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack">VAT ID </span>
          <b-form-group class="mb-0">
            <b-form-input id="VATID" placeholder="VAT ID" v-bind="VATID" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="py-1">
        <b-col>
          <span class="font-weight-bolder text-colorBlack">VAT% </span>
          <b-form-group class="mb-0">
            <b-form-input id="VAT" placeholder="VAT%" v-bind="VAT" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <b-form-group>
      <b-button
        block
        type="submit"
        variant="colorBlue"
        style="margin-top: 400px"
        pill
      >
        Update
      </b-button>
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      departmentName: "",
      organizationName: "",
      organizationNumber: "",
      VATID: "",
      VAT: "",
    };
  },
  async mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
