<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Invoices</h3>
          <p class="font-weight-bold text-colorGrey">
            All Invoices display here
          </p>
        </b-col>
        <b-col md="4" class="px-3 py-1 text-right">
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            class="align-items-center"
            @click="addInvoice"
          >
            <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
            <span class="text-colorWhite">Add Invoice</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fieldsInvoice"
          :items="Invoice"
          thead-class="tableHeadColor"
          small
          rounded
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(OrganizationName)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.OrganizationName ? row.item.OrganizationName : "" }}
              </span>
            </div>
          </template>

          <template #cell(VATId)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.VATId ? row.item.VATId : "" }}
              </span>
            </div>
          </template>

          <template #cell(OrganizationNumber)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{
                  row.item.OrganizationNumber ? row.item.OrganizationNumber : ""
                }}
              </span>
            </div>
          </template>

          <template #cell(VAT)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.VAT ? row.item.VAT : "" }}
              </span>
            </div>
          </template>

          <template #cell(Status)="row">
            <span
              class="font-weight-bolder text-colorBlack"
              style="font-size: 0.8rem"
            >
            </span>
            <b-badge
              pill
              size="sm"
              variant="colorOrange"
              v-if="row.item.Status === 'Sent'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
            <b-badge
              pill
              size="sm"
              variant="success"
              v-if="row.item.Status === 'Paid'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
            <b-badge
              pill
              size="sm"
              variant="danger"
              v-if="row.item.Status === 'Unpaid'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
          </template>

          <template #cell(manage)="">
            <div class="text-right">
              <img
                src="@/assets/images/icons/instructor/assignment/Delete.svg"
                img-fluid
                class="mx-1 cursor-pointer"
                width="30px"
                height="30px"
              />

              <img
                src="@/assets/images/icons/instructor/assignment/Edit.svg"
                img-fluid
                width="30px"
                height="28px"
                class="cursor-pointer"
                @click="editInvoice"
              />
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
    <CreateInvoiceModal
      @modalClosed="onModalClosed"
      :key="`create-${createInvoiceModalCount}`"
    />
    <EditInvoiceModal
      @modalClosed="onModalClosed"
      :key="`edit-${editInvoiceModalCount}`"
    />
  </div>
</template>

<script>
import CreateInvoiceModal from "@/components/uniHead/invoice/CreateInvoiceModal.vue";
import EditInvoiceModal from "@/components/uniHead/invoice/EditInvoiceModal.vue";
export default {
  components: {
    CreateInvoiceModal,
    EditInvoiceModal,
  },
  data() {
    return {
      createInvoiceModalCount: 0,
      editInvoiceModalCount: 0,
      fieldsInvoice: [
        { key: "OrganizationName", label: "Organization Name" },
        { key: "VATId", label: "VAT ID" },
        { key: "OrganizationNumber", label: "Organization Number" },
        { key: "VAT", label: "VAT%" },
        { key: "Status", label: "Status" },
        { key: "manage", label: "" },
      ],
      Invoice: [
        {
          OrganizationName: " Connie",
          VATId: " INV-001-123456",
          OrganizationNumber: " 3434324",
          VAT: " 25%",
          Status: "Sent",
        },
        {
          OrganizationName: " Connie",
          VATId: " INV-001-123456",
          OrganizationNumber: " 3434324",
          VAT: " 25%",
          Status: "Unpaid",
        },
        {
          OrganizationName: " Connie",
          VATId: " INV-001-123456",
          OrganizationNumber: " 3434324",
          VAT: " 25%",
          Status: "Paid",
        },
        {
          OrganizationName: " Connie",
          VATId: " INV-001-123456",
          OrganizationNumber: " 3434324",
          VAT: " 25%",
          Status: "Unpaid",
        },
      ],
      max: 20,
    };
  },
  methods: {
    addInvoice() {
      this.createInvoiceModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("create-invoice-modal");
      });
    },
    editInvoice() {
      this.editInvoiceModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("edit-invoice-modal");
      });
    },
    async onModalClosed() {},
  },
};
</script>

<style></style>
